import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { CLASSES, ANALYTICS, URL_PARAMETERS } from "../../utils/constants";
import { addUrlParams } from "../../utils/functions";
import { AnswerContext } from "../../context/answer-context";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/answer-context";
const appData = require("../../assets/data");

const NextButton = ({ ...props }) => {
  const { next, isActive, curValue } = props;
  const [isBouncing, setIsBouncing] = useState(false);
  const navigate = useNavigate();
  const { searchParamsToString, searchParams } = useContext(GlobalContext);
  const refValue = searchParams.get(URL_PARAMETERS.REF.key);

  const redirectToExternalUrl = (url) => {
    window.location.href = url
  }

  const redirectToInternalUrl = (url) => {
    navigate(url)
  }

  // With this funcion we'll get the right campaign and driver for the form URL
  const buildFormUrl = ({ url, campaignId, driver }) => {
    return `${url}?${campaignId}&${driver}`;
  };

  useEffect(() => {
    curValue ? setIsBouncing(true) : setIsBouncing(false);
  }, [curValue]);

  const handleClick = () => next();
  const handlePrioritySubmit = (priority, url, isFormula) => {
    const solution = appData.priorities.find((item) => item.slug === priority.slug);

    TagManager.dataLayer({
      dataLayer: {
        event: "eventTracker",
        eventCat: ANALYTICS.CATEGORY.QUIZ,
        eventAct: ANALYTICS.ACTION.CHALLENGE,
        eventLbl: priority.title,
      },
    });

    // Setting which type of form we choose
    const formType = URL_PARAMETERS.REF.values.includes(refValue)
      ? "email"
      : "default";
    const formattedFormUrl = buildFormUrl(solution.formUrl[formType]);

    const externalUrl = addUrlParams(formattedFormUrl, { confpage: url })

    // redirectToInternalUrl(url + searchParamsToString());
    redirectToExternalUrl(externalUrl + searchParamsToString());
  };

  return (
    <AnswerContext.Consumer>
      {({ ...state }) => (
        <div>
          {state.priorityChoice ? (
            <button
              form={state.formID}
              className={`next-btn sf-row sf-row-xs--center sf-row-xs--middle ${!state.selectAnotherGoal ? isActive && CLASSES.ACTIVE : ""
                } ${isBouncing && !state.selectAnotherGoal ? CLASSES.ANIMATING : ""
                }`}
              onAnimationEnd={() => {
                setIsBouncing(false);
              }}
              onClick={(e) => {
                e.preventDefault();
                handlePrioritySubmit(
                  state.priorityChoice,
                  state.recommendedURL,
                  state.isFormula
                );
              }}
              disabled={!isActive}
              aria-label="Next"
            >
              <Arrow />
            </button>
          ) : (
            <button
              form={state.formID}
              className={`next-btn sf-row sf-row-xs--center sf-row-xs--middle ${isActive && CLASSES.ACTIVE
                } ${isBouncing ? CLASSES.ANIMATING : ""}`}
              onClick={handleClick}
              onAnimationEnd={() => {
                setIsBouncing(false);
              }}
              disabled={!isActive}
              aria-label="Next"
            >
              <Arrow />
            </button>
          )}
        </div>
      )}
    </AnswerContext.Consumer>
  );
};

export default NextButton;
